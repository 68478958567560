// src/vuetify.ts
import "vuetify/styles"; // Import global Vuetify styles
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { aliases, mdi } from "vuetify/iconsets/mdi"; // Import MDI iconset
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: "mdi", // Set MDI as the default icon set
    aliases,
    sets: {
      mdi,
    },
  },
  theme: {
    themes: {
      light: {
        colors: {
          primary: "#8E8080",
          secondary: "#8E8080",
          info: "#2196F3",
          success: "#2DC41F",
          warning: "#FFEE58",
          darkBlue: "#3c495a",
        },
      },
    },
  },
});

export default vuetify;
