<template>
  <div>
    <Pipeline :pipelineData="pipelineData" type="Users" />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Pipeline from "@/components/pipeline/PipelineFields.vue";

export default defineComponent({
  components: {
    Pipeline,
  },
  data() {
    return {
      pipelineData: null,
    };
  },
  beforeMount() {
    this.getUsersPipeline();
  },
  methods: {
    async getUsersPipeline() {
      const response = (await (this as any).$axios.get(
        "/pipeline/users"
      )) as any;

      this.pipelineData = response.data;
    },
  },
});
</script>
