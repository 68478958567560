<template>
  <v-app style="background: #8e8080">
    <NavBar />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import NavBar from "@/components/default/NavBar.vue"; // @ is an alias to /src

@Options({
  components: {
    NavBar,
  },
})
export default class HomeView extends Vue {}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
