<template>
  <v-container fluid>
    <v-row class="scrolling-columns">
      <v-col
        v-for="(stage, index) in pipelineData"
        :key="index"
        cols="auto"
        class="column px-3"
      >
        <div class="py-3 fixed-headers">
          <div class="d-flex align-center pa-2">
            <v-icon size="24" class="mr-2" :color="stage.color">{{
              stage.icon
            }}</v-icon>
            <div v-text="stage.name" />

            <v-icon>mdi-circle-small</v-icon>

            <div
              class="text-caption font-weight-bold my-1"
              v-text="stage.count + ' ' + type"
            />
          </div>
        </div>

        <div>
          <draggable
            v-model="stage.Users"
            group="items"
            item-key="id"
            :animation="200"
            @change="onMove"
            style="height: 90vh"
          >
            <template #item="{ element }">
              <UserPipelineCard
                class="my-3"
                :data="element"
                v-if="type == 'Users'"
              />
            </template>
          </draggable>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.scrolling-columns {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  height: calc(100vh - 5rem);
  scrollbar-width: thin;
  scrollbar-color: #c4c4c4 #f5f5f5;
}

.column {
  width: 250px;
  margin-right: 16px;
  border-radius: 12px;
  border: 1px solid #c4c4c4;
  background: #f5f5f5;
  overflow-y: scroll;
  padding: 0;
}

.fixed-headers {
  position: sticky;
  top: 0;
  background: #f5f5f5;
  z-index: 1;
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import draggable from "vuedraggable";
import PipelineCard from "@/components/pipeline/PipelineCard.vue";
import UserPipelineCard from "@/components/pipeline/UserPipelineCard.vue";
export default defineComponent({
  components: { draggable, UserPipelineCard },
  props: {
    pipelineData: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "user",
    },
  },
  data() {
    return {
      lists: [
        {
          title: "To Do",
          items: [
            { id: 1, name: "Task 1" },
            { id: 2, name: "Task 2" },
          ],
        },
        {
          title: "In Progress",
          items: [
            { id: 3, name: "Task 3" },
            { id: 4, name: "Task 4" },
          ],
        },
        {
          title: "Done",
          items: [
            { id: 5, name: "Task 5" },
            { id: 6, name: "Task 6" },
          ],
        },
      ],
    };
  },
  methods: {
    onMove(evt: any) {
      // Custom logic during drag and drop
    },
  },
});
</script>
