import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-03aa0d3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-center justify-left pa-2 mr-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_NavigationTabs = _resolveComponent("NavigationTabs")!
  const _component_UserInfo = _resolveComponent("UserInfo")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!

  return (_ctx.$store.getters.isAuthenticated)
    ? (_openBlock(), _createBlock(_component_v_app_bar, {
        key: 0,
        app: "",
        color: "primary",
        style: {"height":"4rem"}
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_v_img, {
              contain: "",
              src: require('@/assets/LogoWithWord.png'),
              transition: "scale-transition",
              width: "150",
              height: "85"
            }, null, 8, ["src"]),
            _createVNode(_component_NavigationTabs, { tabs: _ctx.tabs }, null, 8, ["tabs"])
          ]),
          _createVNode(_component_UserInfo, { items: _ctx.items }, null, 8, ["items"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}