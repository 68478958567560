import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-00b8b146"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ml-auto mr-6 d-flex align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_menu, {
      "offset-y": "",
      "close-on-content-click": true,
      activator: "parent"
    }, {
      activator: _withCtx(({ on, attrs }) => [
        _createVNode(_component_v_btn, _mergeProps(attrs, _toHandlers(on), {
          class: "d-flex align-center",
          text: ""
        }), {
          default: _withCtx(() => [
            _createVNode(_component_v_avatar, {
              color: "grey",
              class: "mr-2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_img, {
                  src: _ctx.userAvatar,
                  alt: "User Avatar"
                }, null, 8, ["src"])
              ]),
              _: 1
            }),
            _createElementVNode("span", null, _toDisplayString(_ctx.userName), 1),
            _createVNode(_component_v_icon, { right: "" }, {
              default: _withCtx(() => [
                _createTextVNode("mdi-chevron-down")
              ]),
              _: 1
            })
          ]),
          _: 2
        }, 1040)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_list, { class: "mt-3" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_list_item, { disabled: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_avatar, {
                  color: "grey",
                  class: "mr-2"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_img, {
                      src: _ctx.userAvatar,
                      alt: "User Avatar"
                    }, null, 8, ["src"])
                  ]),
                  _: 1
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.userName), 1)
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
              return (_openBlock(), _createBlock(_component_v_list_item, {
                key: index,
                onClick: ($event: any) => (_ctx.handleClick(item))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_title, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, { class: "mr-2" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.icon), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createTextVNode(" " + _toDisplayString(item.title), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}