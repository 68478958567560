import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6b14e6e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "py-3 fixed-headers" }
const _hoisted_2 = { class: "d-flex align-center pa-2" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_UserPipelineCard = _resolveComponent("UserPipelineCard")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { fluid: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { class: "scrolling-columns" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pipelineData, (stage, index) => {
            return (_openBlock(), _createBlock(_component_v_col, {
              key: index,
              cols: "auto",
              class: "column px-3"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_v_icon, {
                      size: "24",
                      class: "mr-2",
                      color: stage.color
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(stage.icon), 1)
                      ]),
                      _: 2
                    }, 1032, ["color"]),
                    _createElementVNode("div", {
                      textContent: _toDisplayString(stage.name)
                    }, null, 8, _hoisted_3),
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => [
                        _createTextVNode("mdi-circle-small")
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", {
                      class: "text-caption font-weight-bold my-1",
                      textContent: _toDisplayString(stage.count + ' ' + _ctx.type)
                    }, null, 8, _hoisted_4)
                  ])
                ]),
                _createElementVNode("div", null, [
                  _createVNode(_component_draggable, {
                    modelValue: stage.Users,
                    "onUpdate:modelValue": ($event: any) => ((stage.Users) = $event),
                    group: "items",
                    "item-key": "id",
                    animation: 200,
                    onChange: _ctx.onMove,
                    style: {"height":"90vh"}
                  }, {
                    item: _withCtx(({ element }) => [
                      (_ctx.type == 'Users')
                        ? (_openBlock(), _createBlock(_component_UserPipelineCard, {
                            key: 0,
                            class: "my-3",
                            data: element
                          }, null, 8, ["data"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["modelValue", "onUpdate:modelValue", "onChange"])
                ])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}