<template>
  <div style="overflow: hidden">
    <div>
      <v-img
        contain
        :src="require('@/assets/LogoWithWord.png')"
        transition="scale-transition"
        width="300"
        height="150"
      />
    </div>
    <v-container class="fill-height mt-16" fluid>
      <v-row justify="center" align="center">
        <v-col cols="12" md="4">
          <v-card style="border-radius: 12px">
            <v-icon
              size="50"
              class="mt-6"
              style="
                background: #8e8080;
                border-radius: 50%;
                padding: 40px;
                opacity: 0.6;
              "
            >
              mdi-login</v-icon
            >
            <v-card-title class="text-h5">Login </v-card-title>
            <v-card-text>
              <v-form>
                <v-text-field
                  v-model="email"
                  label="Email"
                  type="email"
                  required
                  variant="outlined"
                  prepend-inner-icon="mdi-email"
                ></v-text-field>

                <v-text-field
                  variant="outlined"
                  v-model="password"
                  label="Password"
                  type="password"
                  required
                  prepend-inner-icon="mdi-lock"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                @click="login"
                class="text-none text-subtitle-1 ma-2 mt-n6"
                color="primary"
                size="small"
                variant="flat"
                width="100"
                height="40"
                :loading="loading"
                :disabled="!email || !password"
                >Login
                <v-icon right class="ml-1">mdi-login</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from "vue-router"; // Import useRouter to access the router
import { useStore } from "vuex"; // Import useStore to access Vuex store

export default defineComponent({
  data() {
    return {
      loading: false,
      email: "",
      password: "",
    };
  },
  methods: {
    async login() {
      this.loading = true;

      try {
        const response = await (this as any).$axios.post("/login/hero", {
          email: this.email,
          password: this.password,
        });

        if (response.data) {
          this.$store.dispatch("login", {
            user: response.data.user,
            token: response.data.token,
          });

          this.$router.push({
            name: "Home",
          });
        }
      } catch (error) {
        console.error("Login failed:", error);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style scoped>
.fill-height {
  height: 100vh;
}
</style>
