// src/main.ts
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axiosPlugin from "./plugins/axios";
import momentPlugin from "./plugins/moment";
import globalMethods from "./plugins/globalMethods";

const app = createApp(App);

app.use(store);
app.use(router);
app.use(vuetify);
app.use(globalMethods); // Register global methods plugin
app.use(axiosPlugin); // Register Axios plugin
app.use(momentPlugin); // Register Moment.js plugin

app.mount("#app");
