<template>
  <div>orders</div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {},
  data() {
    return {
      // Add your data properties here
    };
  },
  computed: {
    // Add your computed properties here
  },
  watch: {
    // Add your watch properties here
  },
  methods: {
    // Add your methods here
  },
});
</script>
<style scoped>
/* Add your component styles here */
</style>
