// src/plugins/globalMethods.ts
import { App } from "vue";
import moment from "moment";

export default {
  install(app: App) {
    app.config.globalProperties.$sanitizeUrl = (value: string) => {
      return value
        ? value.includes("http://") || value.includes("https://")
          ? value
          : "http://" + value
        : null;
    };

    app.config.globalProperties.$formatDate = (
      value: string,
      format = "hh:mm DD/MM/YYYY"
    ) => {
      return value ? moment(value).format(format) : null;
    };

    app.config.globalProperties.$fromNow = (
      value: string,
      shortHand = false
    ) => {
      if (!value) return null;
      const fromNow = moment(value).fromNow();
      if (!shortHand) return fromNow;
      else {
        return fromNow
          .replace("a few seconds ago", "now")
          .replace(/(?<!\S)(a|an)(?!\S)/i, "1")
          .replace(/years?/i, "y")
          .replace(/months?/i, "mo")
          .replace(/days?/i, "d")
          .replace(/hours?/i, "h")
          .replace(/minutes?/i, "m")
          .replace(/seconds?/i, "s")
          .replace(/ago/i, "")
          .replace(/\b(\d+)\s+([a-z]{1,2})?\b/gi, "$1$2")
          .trim();
      }
    };

    app.config.globalProperties.$infoBetweenDates = (
      startDate: string,
      endDate: string
    ) => {
      const start = moment(startDate);
      const end = moment(endDate);
      const totalDays = moment(end).diff(start, "days");
      const daysPassed = moment().diff(start, "days");
      const daysLeft = moment(end).diff(moment(), "days");
      const result = Math.round((daysPassed / totalDays) * 100);
      return { percentDone: result, daysPassed, totalDays, daysLeft };
    };

    app.config.globalProperties.$toCurrency = (value: number) => {
      return value ? Intl.NumberFormat("en-US").format(value) : 0;
    };

    app.config.globalProperties.$fullName = (user: any) => {
      let fullName = "";
      if (user) {
        if (user.fullName) return user.fullName;
        if (user.first_name) fullName += user.first_name;
        if (user.last_name)
          fullName += user.first_name ? " " + user.last_name : user.last_name;
      }
      return fullName;
    };

    app.config.globalProperties.$daysDifference = (value: string) => {
      if (value) {
        const result = Math.abs(moment(value).diff(moment(), "days"));
        if (result == 0) return moment(String(value)).fromNow();
        return result.toString() + " days ago";
      }
    };

    app.config.globalProperties.$formatLongDate = (value: string) => {
      if (value) {
        return new Date(value).toLocaleDateString(undefined, {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      }
    };
  },
};
