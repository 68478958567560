<template>
  <v-app-bar
    app
    color="primary"
    style="height: 4rem"
    v-if="$store.getters.isAuthenticated"
  >
    <!-- Logo on the left -->
    <div class="d-flex align-center justify-left pa-2 mr-auto">
      <v-img
        contain
        :src="require('@/assets/LogoWithWord.png')"
        transition="scale-transition"
        width="150"
        height="85"
      />
      <!-- Navigation Tabs -->
      <NavigationTabs :tabs="tabs" />
    </div>

    <!-- User Info on the right -->
    <UserInfo :items="items" />
  </v-app-bar>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NavigationTabs from "@/components/default/NavigationTabs.vue";
import UserInfo from "@/components/default/UserInfo.vue";
export default defineComponent({
  name: "Navbar",
  components: {
    NavigationTabs,
    UserInfo,
  },
  data() {
    return {
      tabs: [
        { name: "Models", route: "/models-crm" },
        { name: "Stores", route: "/stores-crm" },
        { name: "Users", route: "/users-crm" },
        { name: "Orders", route: "/orders-crm" },
        { name: "Delivery", route: "/delivery-crm" },
        { name: "Reviews", route: "/reviews-crm" },
      ],
      items: [
        { title: "Admin Console", icon: "mdi-view-dashboard" },
        { title: "Logout", icon: "mdi-logout" },
      ],
    };
  },
});
</script>

<style scoped>
.v-toolbar-title {
  margin-right: 16px;
}

.v-avatar {
  margin-right: 8px;
}
</style>
