import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!

  return (_openBlock(), _createBlock(_component_v_tabs, {
    modelValue: _ctx.activeTab,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
    "align-tabs": "center"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
        return (_openBlock(), _createBlock(_component_v_tab, {
          key: tab.name,
          to: tab.route,
          exact: true,
          href: tab.route
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(tab.name), 1)
          ]),
          _: 2
        }, 1032, ["to", "href"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}