import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Default from "../views/Default.vue";
import AuthorizationPage from "../views/Authorization.vue";
import ModelsCRM from "../views/ModelsCRM.vue";
import UserCRM from "../views/UserCRM.vue";
import StoresCRM from "../views/StoresCRM.vue";
import Reviews from "../views/Reviews.vue";
import Orders from "../views/Orders.vue";
import DeliveryCRM from "../views/DeliveryCRM.vue";
import store from "../store"; // Import the store

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Default,
  },
  {
    path: "/login",
    name: "AuthorizationPage",
    component: AuthorizationPage,
  },
  {
    path: "/models-crm",
    name: "ModelsCRM",
    component: ModelsCRM,
  },
  {
    path: "/users-crm",
    name: "UserCRM",
    component: UserCRM,
  },
  {
    path: "/stores-crm",
    name: "StoresCRM",
    component: StoresCRM,
  },
  {
    path: "/reviews-crm",
    name: "ReviewsCRM",
    component: Reviews,
  },
  {
    path: "/orders-crm",
    name: "OrdersCRM",
    component: Orders,
  },
  {
    path: "/delivery-crm",
    name: "DeliveryCRM",
    component: DeliveryCRM,
  },
  {
    path: "/logout",
    name: "Logout",
    redirect: { name: "AuthorizationPage" },
    beforeEnter: (to, from, next) => {
      store.dispatch("logout");
      next({ name: "AuthorizationPage" });
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Add the global navigation guard here
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.isAuthenticated;
  if (!isAuthenticated && to.name !== "AuthorizationPage") {
    next({ name: "AuthorizationPage" }); // Redirect to the login page if not authenticated
  } else if (isAuthenticated && to.name === "AuthorizationPage") {
    next({ name: "Home" }); // Redirect to the home page if authenticated
  } else {
    next(); // Proceed to the route
  }
});

export default router;
