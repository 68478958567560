<template>
  <div
    style="background: #fff; border: 1px solid #c4c4c4; border-radius: 12px"
    class="pa-2"
  >
    <div class="d-flex align-center">
      <v-avatar color="grey-darken-3" size="50" :image="data.photo"></v-avatar>
      <div
        class="font-weight-bold text-capitalize text-no-wrap ml-2 text-caption"
        v-text="$fullName(data)"
      />
    </div>
    <div class="text-left mt-2 ml-2">
      <div class="mb-3">
        <v-icon size="16" class="mr-2">mdi-email</v-icon>
        <span class="text-caption" v-text="data.email" />
      </div>
      <div>
        <v-icon size="16" class="mr-2">mdi-phone</v-icon>
        <span v-text="data.phoneNumber" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // Add your data properties here
    };
  },
  computed: {
    // Add your computed properties here
  },
  watch: {
    // Add your watch properties here
  },
  methods: {
    // Add your methods here
  },
});
</script>
<style scoped>
/* Add your component styles here */
</style>
