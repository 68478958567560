import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-42d32cfb"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  style: {"background":"#fff","border":"1px solid #c4c4c4","border-radius":"12px"},
  class: "pa-2"
}
const _hoisted_2 = { class: "d-flex align-center" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = { class: "text-left mt-2 ml-2" }
const _hoisted_5 = { class: "mb-3" }
const _hoisted_6 = ["textContent"]
const _hoisted_7 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_avatar, {
        color: "grey-darken-3",
        size: "50",
        image: _ctx.data.photo
      }, null, 8, ["image"]),
      _createElementVNode("div", {
        class: "font-weight-bold text-capitalize text-no-wrap ml-2 text-caption",
        textContent: _toDisplayString(_ctx.$fullName(_ctx.data))
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_v_icon, {
          size: "16",
          class: "mr-2"
        }, {
          default: _withCtx(() => [
            _createTextVNode("mdi-email")
          ]),
          _: 1
        }),
        _createElementVNode("span", {
          class: "text-caption",
          textContent: _toDisplayString(_ctx.data.email)
        }, null, 8, _hoisted_6)
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_v_icon, {
          size: "16",
          class: "mr-2"
        }, {
          default: _withCtx(() => [
            _createTextVNode("mdi-phone")
          ]),
          _: 1
        }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.data.phoneNumber)
        }, null, 8, _hoisted_7)
      ])
    ])
  ]))
}