<template>
  <div class="ml-auto mr-6 d-flex align-center">
    <v-menu offset-y :close-on-content-click="true" activator="parent">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" class="d-flex align-center" text>
          <v-avatar color="grey" class="mr-2">
            <v-img :src="userAvatar" alt="User Avatar"></v-img>
          </v-avatar>
          <span>{{ userName }}</span>
          <v-icon right>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list class="mt-3">
        <v-list-item disabled>
          <v-avatar color="grey" class="mr-2">
            <v-img :src="userAvatar" alt="User Avatar"></v-img>
          </v-avatar>
          <span>{{ userName }}</span>
        </v-list-item>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="handleClick(item)"
        >
          <v-list-item-title>
            <v-icon class="mr-2">{{ item.icon }}</v-icon>
            {{ item.title }}</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "UserInfo",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    userName() {
      return (
        this.$store.getters.user.first_name +
        " " +
        this.$store.getters.user.last_name
      );
    },
    userAvatar() {
      return this.$store.getters.user.photo;
    },
  },
  methods: {
    handleClick(item: any) {
      if (item.title === "Logout") {
        this.$store.dispatch("logout");
        this.$router.push({ name: "AuthorizationPage" });
      }
    },
  },
});
</script>

<style scoped>
.v-btn {
  padding: 0;
}
.v-avatar {
  margin-right: 8px;
}
.v-icon {
  margin-left: 8px; /* تباعد للسهم */
}
</style>
