import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createTextVNode(" icon is here "),
    _createVNode(_component_v_icon, {
      size: "50",
      color: "red"
    }, {
      default: _withCtx(() => [
        _createTextVNode("mdi-home")
      ]),
      _: 1
    }),
    _createTextVNode("home ")
  ]))
}