<template>
  <v-tabs v-model="activeTab" align-tabs="center">
    <v-tab
      v-for="tab in tabs"
      :key="tab.name"
      :to="tab.route"
      :exact="true"
      :href="tab.route"
    >
      {{ tab.name }}
    </v-tab>
  </v-tabs>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "NavigationTabs",
  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },
  computed: {
    activeTab() {
      return this.$route.path;
    },
  },
});
</script>

<style scoped></style>
