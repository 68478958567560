import axios from "axios";
import router from "@/router";
import { ActionContext } from "vuex";

// Define the state type
interface AuthState {
  user: User | null;
  token: string | null;
}

// Define the user type (you can expand this according to your user object structure)
interface User {
  roleId: number;
  [key: string]: any;
}

// Initial state
const state: AuthState = {
  user: null,
  token: null,
};

// Getters with proper typing
const getters = {
  isAuthenticated: (state: AuthState): boolean => !!state.user,
  roleId: (state: AuthState): number | undefined => state.user?.roleId,
  user: (state: AuthState): User | null => state.user,
  token: (state: AuthState): string | null => state.token,
};

// Actions with proper typing
const actions = {
  async getUserInfo(
    { commit, dispatch }: ActionContext<AuthState, unknown>,
    userId: string
  ) {
    try {
      const response = await axios.get(`/users/${userId}`);
      if (!response.data) {
        await dispatch("logout");
        router.push("Login");
      } else {
        await commit("updateUser", response.data);
      }
    } catch (error) {
      console.error(error);
      dispatch("logout");
    }
  },
  async logout({ dispatch }: ActionContext<AuthState, unknown>) {
    await dispatch("commitUserInfo", { user: null, token: null });
  },

  async login(
    { dispatch }: ActionContext<AuthState, unknown>,
    data: { user: User | null; token: string | null }
  ) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
    await dispatch("commitUserInfo", data);
  },

  async commitUserInfo(
    { commit }: ActionContext<AuthState, unknown>,
    data: { user: User | null; token: string | null }
  ) {
    commit("setUser", data.user);
    commit("setToken", data.token);
  },

  async validateToken({ commit, dispatch }: ActionContext<AuthState, unknown>) {
    try {
      const { data } = await axios.get("/users/self");
      if (data && typeof data === "object" && data.id) {
        commit("setUser", data);
      } else {
        throw new Error("Invalid user data");
      }
    } catch (error) {
      await dispatch("logout");
      throw error;
    }
  },
};

// Mutations with proper typing
const mutations = {
  updateUser(state: AuthState, data: Partial<User>) {
    if (state.user) {
      Object.assign(state.user, data);
    }
  },

  setUser(state: AuthState, user: User | null) {
    state.user = user;
  },

  setToken(state: AuthState, token: string | null) {
    state.token = token;
  },
};

// Export the module with proper typing
export default {
  state,
  getters,
  actions,
  mutations,
};
