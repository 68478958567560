// src/plugins/axios.ts
import axios from "axios";
import { App } from "vue";

export default {
  install(app: App) {
    app.config.globalProperties.$axios = axios;
    axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  },
};
