<template>
  <div class="home">
    icon is here <v-icon size="50" color="red">mdi-home</v-icon>home
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class HomeView extends Vue {}
</script>
